import { BookOpenIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { UtilityNavigationBarProps } from '../types/header'

const UtilityLinks = (props: UtilityNavigationBarProps) => {
  const { myAccountLabel, cpiLearningLabel } = props

  return (
    <>
      {cpiLearningLabel && (
        <a
          href="https://www.crisisprevention.com/Learning"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="text-sm flex items-center space-x-2 py-1 pl-0 2xl:pl-3 pr-3 text-dark-blue-primary"
        >
          <span className="w-5 h-5">
            <BookOpenIcon className="w-5 h-5 text-dark-blue-primary" />
          </span>
          <span className="uppercase">{cpiLearningLabel}</span>
        </a>
      )}
      {myAccountLabel && (
        <a
          href="https://www.crisisprevention.com/Sign-In"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="text-sm flex items-center space-x-2 py-1 pl-0 2xl:pl-3 pr-3 text-dark-blue-primary"
        >
          <span className="w-5 h-5">
            <UserCircleIcon className="w-5 h-5 text-dark-blue-primary" />
          </span>
          <span className="uppercase">{myAccountLabel}</span>
        </a>
      )}
    </>
  )
}

export default UtilityLinks
