import CountrySelector from '@/components/shared/header/components/country-selector'
import { PhoneIcon } from '@heroicons/react/24/solid'
import { HeaderProps } from '../types/header'
import UtilityLinks from './utility-links'

function Utility(props: Readonly<HeaderProps>) {
  return (
    <div className="bg-grey-secondary-light p-2 rounded-br-lg rounded-bl-lg">
      <div className="flex justify-between space-x-4">
        <div className="flex items-center">
          {props.model.utilityNavigationBar?.phoneLink?.href && (
            <a
              href={props.model.utilityNavigationBar.phoneLink?.href}
              className="flex items-center space-x-2 text-sm font-medium"
            >
              <span>
                <PhoneIcon className="fill-dark-blue-primary w-5 h-5" />
              </span>
              <span>{props.model.utilityNavigationBar.phoneLink?.text}</span>
            </a>
          )}

          {props.model.utilityNavigationBar?.whatsLink?.href && (
            <a
              href={props.model.utilityNavigationBar.whatsLink?.href}
              className="flex items-center space-x-2 text-sm font-medium ml-6"
              target="_blank"
            >
              <span>
                <img src="https://www.crisisprevention.com/globalassets/global-assets/icons/whatsapp-logo-primary.svg" alt="whats-app-logo-uae" className='w-5 h-5' />
              </span>
              <span>{props.model.utilityNavigationBar.whatsLink?.text}</span>
            </a>
          )}
        </div>
        <div className="flex items-center space-x-1">
          <CountrySelector />
          <UtilityLinks {...props.model.utilityNavigationBar} />
        </div>
      </div>
    </div>
  )
}

export default Utility
