import SubNavItem from '@/components/shared/header/components/sub-nav-items'
import { usePrimaryMenuStore } from '@/stores/primary-menu'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import SharedLink from '../../buttons/shared-link'
import { PrimaryMenuNavItem } from '../types/header'

function Navigation(props: Readonly<PrimaryMenuNavItem>) {
  const { model, menuIndex } = props
  const {
    activeMenuIndex,
    activeSubMenuIndex,
    incrementActiveSubMenuIndex,
    resetActiveMenuIndex,
    resetActiveSubMenuIndex,
    updateActiveMenuIndex,
    updateActiveSubMenuIndex,
    remainingContainerSpace,
  } = usePrimaryMenuStore()

  const megaMenu = useRef<HTMLDivElement>(null)
  const [isMenuOverflow, setIsMenuOverflow] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleActiveIndex = (index: number) => {
    if (activeMenuIndex !== index) {
      updateActiveMenuIndex(index)
      updateActiveSubMenuIndex(0) // set first child element to active
    }
  }

  const handleActiveSubMenuIndex = (index: number) => {
    updateActiveSubMenuIndex(index)
  }

  const handleFocusElementLink = (e: any) => {
    resetActiveMenuIndex()
    resetActiveSubMenuIndex()

    if (e.code === 'ArrowDown' || e.code === 'Space') {
      updateActiveMenuIndex(menuIndex) // init active index for key events
      updateActiveSubMenuIndex(0) // set first child element to active
    }
  }

  const handleFocusElementButton = (e: any) => {
    resetActiveMenuIndex()
    resetActiveSubMenuIndex()

    if (e.code === 'ArrowDown' || e.code === 'Space' || e.code === 'Enter') {
      updateActiveMenuIndex(menuIndex) // init active index for key events
      updateActiveSubMenuIndex(0) // set first child element to active
    }
  }

  const handleFocusAwayElement = (e: any) => {
    if (e.code === 'ShiftLeft') {
      resetActiveMenuIndex()
      resetActiveSubMenuIndex()
    }
  }

  const handleTabIndex = (itemIndex: number) => {
    let tabIndex = -1

    if (activeMenuIndex === menuIndex) {
      tabIndex = 1 //sets all index values to 1 when menu is open
    }

    if (activeSubMenuIndex === itemIndex) {
      tabIndex = 0 // sets all index values to 0 when the sub nav item is active
    }

    return tabIndex
  }

  const handleBtnMenuScrollEvents = () => {
    const preventScrollBtn = document.querySelectorAll('.prevent-scroll-btn')

    if (preventScrollBtn) {
      preventScrollBtn.forEach((btn) => {
        btn.addEventListener('keydown', (e: any) => {
          if (e.code === 'ArrowDown' || e.code === 'Space') {
            e.preventDefault() // prevent default broswer scroll on arrow down and space
          }
        })
      })
    }
  }

  const handleEscCloseMenu = () => {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        resetActiveMenuIndex()
        resetActiveSubMenuIndex()
      }
    })
  }

  const handleKeyDown = (e: any, isLastColumnItem: boolean) => {
    if (isLastColumnItem) {
      resetActiveMenuIndex()
      resetActiveSubMenuIndex()
    } else if (e.code === 'Tab') {
      e.preventDefault()
      incrementActiveSubMenuIndex()
    }
  }

  const handleCTALinkCheck = (link: any) => {
    let hasLink = false

    if (link) {
      hasLink = true
    }

    return hasLink
  }

  const handleMenuOverflow = () => {
    if (remainingContainerSpace > 0) {
      if (
        megaMenu.current &&
        megaMenu.current.getBoundingClientRect().right > remainingContainerSpace
      ) {
        setIsMenuOverflow(true)
      }
    }
  }

  useEffect(() => {
    if (activeMenuIndex === menuIndex) {
      handleMenuOverflow() // check overflow when menu is active
      setMenuIsOpen(true)
    } else {
      setMenuIsOpen(false)
      setTimeout(() => {
        setIsMenuOverflow(false) // reset value when menu is not active. set timeout so animation finishes
      }, 400);
    }
  }, [activeMenuIndex])

  useEffect(() => {
    handleBtnMenuScrollEvents()
    handleEscCloseMenu()
  }, [])

  return (
    <li
      className={isMenuOverflow ? '' : 'relative'}
      onMouseEnter={() => handleActiveIndex(menuIndex)}
    >
      {model.primaryLink ? (
        <Link
          to={model.primaryLink.href}
          id={`menuBtn-${menuIndex}`}
          target={model.primaryLink.target}
          aria-label={model.primaryLink.title}
          className={`prevent-scroll-btn flex whitespace-nowrap p-2 rounded-lg font-medium text-dark-blue-primary hover:no-underline shared-transition ${
            activeMenuIndex !== menuIndex
              ? 'bg-white'
              : 'bg-grey-secondary-light'
          }`}
          aria-haspopup="true"
          aria-controls={`subMenu-${menuIndex}`}
          onKeyUp={handleFocusElementLink}
          onKeyDown={handleFocusAwayElement}
        >
          {model.primaryLink.text}
        </Link>
      ) : (
        <>
          {model.title && (
            <button
              id={`menuBtn-${menuIndex}`}
              aria-label={model.title}
              className={`prevent-scroll-btn flex whitespace-nowrap p-2 rounded-lg font-medium text-dark-blue-primary hover:no-underline shared-transition ${
                activeMenuIndex !== menuIndex
                  ? 'bg-white'
                  : 'bg-grey-secondary-light'
              }`}
              aria-haspopup="true"
              aria-controls={`subMenu-${menuIndex}`}
              onKeyUp={handleFocusElementButton}
              onKeyDown={handleFocusAwayElement}
            >
              {model.title}
            </button>
          )}
        </>
      )}
      {model.columns && (
        <div
          ref={megaMenu}
          id={`subMenu-${menuIndex}`}
          aria-labelledby={`menuBtn-${menuIndex}`}
          className={`bg-white border border-grey-200 shadow-xl rounded-lg flex absolute z-25 shared-transition 
          ${isMenuOverflow ? 'top-16 right-0' : 'top-full -left-4'}
          ${
            menuIsOpen
              ? 'visible opacity-1 translate-y-2'
              : 'invisible opacity-0 translate-y-0 h-0 overflow-hidden'
          }`}
        >
          <ul className="w-[264px] h-full py-2 flex flex-col">
            {model.columns?.map((subItem, j) => (
              <li key={j} role="none" className="w-full">
                {subItem.model.link ? (
                  <SharedLink
                    {...subItem.model.link}
                    className={`hover:no-underline hover:bg-grey-secondary-light pt-2.5 pb-2 px-3 flex flex-col w-full shared-transition ${
                      activeSubMenuIndex !== j
                        ? 'bg-white'
                        : 'bg-grey-secondary-light'
                    }`}
                    onMouseOver={() => handleActiveSubMenuIndex(j)}
                    onFocus={() => handleActiveSubMenuIndex(j)}
                    tabIndex={handleTabIndex(j)}
                  >
                    <span className="text-grey-dark font-bold text-sm">
                      {subItem.model.link.text}
                    </span>
                    {subItem.model.description && (
                      <span className="text-grey-light text-sm">
                        {subItem.model.description}
                      </span>
                    )}
                  </SharedLink>
                ) : (
                  <button
                    className="bg-white hover:bg-grey-secondary-light hover:cursor-default shared-transition pt-2.5 pb-2 px-3 flex flex-col w-full text-left"
                    onMouseOver={() => handleActiveSubMenuIndex(j)}
                    onFocus={() => handleActiveSubMenuIndex(j)}
                    tabIndex={handleTabIndex(j)}
                  >
                    {subItem.model.name && (
                      <span className="text-grey-dark font-bold text-sm">
                        {subItem.model.name}
                      </span>
                    )}
                    {subItem.model.description && (
                      <span className="text-grey-light text-sm">
                        {subItem.model.description}
                      </span>
                    )}
                  </button>
                )}
              </li>
            ))}
          </ul>

          <div>
            {model.columns?.map((subItem, i) => (
              <React.Fragment key={i}>
                {subItem.model.submenu && subItem.model.submenu.length > 0 && (
                  <div
                    className={`w-[546px] overflow-hidden ${
                      i === activeSubMenuIndex
                        ? 'h-full border-l border-grey-200'
                        : 'h-0'
                    }`}
                  >
                    <div
                      className={`w-full h-full flex flex-col justify-between shared-transition ${
                        i === activeSubMenuIndex
                          ? 'visible opacity-1'
                          : 'invisible opacity-0 h-0 overflow-hidden'
                      }`}
                    >
                      {subItem.model.submenu && (
                        <ul className="w-full grid grid-cols-2 gap-y-4 gap-x-6 p-7">
                          {subItem.model.submenu.map((item, j) => (
                            <li key={j} className="col-span-1">
                              <SubNavItem
                                {...item}
                                menuIndex={menuIndex}
                                itemIndex={i}
                                isLastItem={subItem.model.submenu?.length === j}
                                hasCTAlink={handleCTALinkCheck(
                                  subItem.model.link,
                                )}
                                isLastColumnItem={
                                  model.columns?.length === i + 1
                                }
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                      {model.calloutLink && (
                        <SharedLink
                          {...model.calloutLink}
                          className="group w-full flex space-x-2 justify-between p-3 hover:no-underline bg-dark-blue-100 hover:bg-dark-blue-primary"
                          tabIndex={activeSubMenuIndex === i ? 0 : -1}
                          onKeyDown={(e) =>
                            handleKeyDown(e, model.columns?.length === i + 1)
                          }
                        >
                          <span className="text-black group-hover:text-white shared-transition">
                            {model.calloutLink.text}
                          </span>
                          <span className="flex">
                            <ArrowRightIcon className="text-[#0F172A] group-hover:text-white h-5 w-5 shared-transition" />
                          </span>
                        </SharedLink>
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </li>
  )
}

export default Navigation
